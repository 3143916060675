export default {
  headers: [
    {
      id: '-piskera-icons-header',
      logo: {
        alt: 'logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/piskera/logo.png',
        url: 'https://www.kutterrealestate.com/',
      },
      breakpoint: 925,
      desktopMenu: {
        type: 'default', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: false,
          reverseOrder: true,
          containerClassName: '',
          imageClassName: '',
        },
        headerClassName: 'on-piskera-iheader',
        headerSectionsClassName: 'on-piskera-iheader-section',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: 'on-piskera-iheader-item',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'piskera-top-header-phone-number',
            content: '832-588-1006',
            className: '',
            url: 'tel:832-588-1006',
          },
          {
            id: 'on-piskera-iheader-fb',
            iconSet: 'fab',
            className: 'facebook',
            url: 'https://www.facebook.com/kutterrealestate/',
          },
          {
            id: 'on-piskera-iheader-instagram',
            iconSet: 'fab',
            className: 'instagram',
            url: 'https://www.instagram.com/kutterrealestate/',
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: false,
          containerClassName: 'on-piskera-mheader-logo-container',
          imageClassName: 'on-piskera-mheader-logo',
        },
        type: 'mobile-reversed', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: 'on-piskera-hamburger',
          hide: false,
          reverse: true,
        },
        headerClassName: 'on-piskera-mheader-socials',
        headerSectionsClassName: 'on-piskera-mheader-section',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: 'on-piskera-mheader-menu',
        itemClassName: 'on-piskera-mheader-item-hamb',
        menuClassName: 'on-piskera-mheader-folder',
        menuLabelActiveClassName: '',
        menuItemClassName: 'on-piskera-mheader-folder-item',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [
          {
            id: 'piskera-top-header-phone-number-small',
            content: '832-588-1006',
            className: '',
            url: 'tel:832-588-1006',
          },
        ],
        items: [
          {
            id: 'piskera-cash-offer-mobile',
            content: 'CASH OFFER',
            url: 'https://cash-offer-piskera.netlify.app/',
          },
          {
            id: 'piskera-trade-in-mobile',
            content: 'TRADE IN',
            url: 'https://trade-in-piskera.netlify.app/',
          },
          {
            id: 'on-piskera-header-mobile-item-contact',
            content: 'CONTACT',
            url: 'https://www.kutterrealestate.com/contact/',
          },
          {
            id: 'on-piskera-header-mobile-item-listings-search',
            content: 'LISTINGS SEARCH',
            url: 'https://www.kutterrealestate.com/listings-search/',
          },
          {
            id: 'on-piskera-header-mobile-item-about',
            content: 'ABOUT',
            url: 'https://www.kutterrealestate.com/about/',
          },
          {
            id: 'on-piskera-header-mobile-item-blog',
            content: 'BLOG',
            url: 'https://www.kutterrealestate.com/blog/',
          },
          {
            id: 'on-piskera-header-mobile-item-home',
            content: 'HOME',
            url: 'https://www.kutterrealestate.com/',
          },
          {
            id: 'on-piskera-header-mobile-item-harvey-relief',
            content: 'HARVEY RELIEF',
            url: 'https://www.kutterrealestate.com/harvey-relief',
          },
        ],
      },
    },
    {
      breakpoint: 0,
      id: 'on-piskera-iheader-hr-all',
      desktopMenu: {
        type: 'default', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: false,
          reverseOrder: true,
          containerClassName: '',
          imageClassName: '',
        },
        headerClassName: 'on-piskera-iheader-hr',
        headerSectionsClassName: 'on-piskera-iheader-section-hr',
        itemClassName: 'on-piskera-iheader-item-hr',
        items: [
          {
            id: 'piskera-middle-hr',
            content: '',
            className: '',
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: false,
          containerClassName: '',
          imageClassName: '',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: '',
          hide: false,
          reverse: false,
        },
        headerClassName: '',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: '',
        itemClassName: '',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [],
      },
    },
    {
      id: 'main-header',
      logo: {
        alt: 'logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/piskera/logo.png',
        url: 'https://www.kutterrealestate.com/',
      },
      breakpoint: 760,
      desktopMenu: {
        type: 'default', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: true,
          reverseOrder: false,
          containerClassName: 'on-piskera-header-logo-container',
          imageClassName: 'on-piskera-header-logo',
        },
        headerClassName: 'on-piskera-header',
        headerSectionsClassName: 'on-piskera-header-section',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: '',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'on-piskera-header-agency-name',
            menuItems: [
              {
                id: 'on-piskera-header-agency',
                content: 'Kutter Real Estate Team',
                url: 'https://www.kutterrealestate.com/',
              },
              {
                id: 'on-piskera-header-agency-message',
                content: 'We are here to serve you!',
              },
            ],
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: true,
          containerClassName: 'on-piskera-mheader-logo-container',
          imageClassName: 'on-piskera-mheader-logo',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: 'on-piskera-hamburger',
          hide: true,
          reverse: false,
        },
        headerClassName: 'on-piskera-mheader-second',
        headerSectionsClassName: 'on-piskera-mheader-section-second',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: 'on-piskera-mheader-menu-second',
        itemClassName: 'on-piskera-mheader-item',
        menuClassName: 'on-piskera-mheader-folder',
        menuLabelActiveClassName: '',
        menuItemClassName: 'on-piskera-mheader-folder-item',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [
          {
            id: 'on-piskera-header-agency-name-small',
            menuItems: [
              {
                id: 'on-piskera-header-agency-small',
                content: 'Kutter Real Estate Team',
                url: 'https://www.kutterrealestate.com/',
              },
              {
                id: 'on-piskera-header-agency-message-small',
                content: 'We are here to serve you!',
              },
            ],
          },
        ],
        items: [],
      },
    },
    {
      id: 'content-header',
      logo: {
        alt: 'logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/piskera/logo.png',
        url: 'https://www.kutterrealestate.com/',
      },
      breakpoint: 0,
      desktopMenu: {
        type: 'default', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: false,
          reverseOrder: false,
          containerClassName: '',
          imageClassName: '',
        },
        headerClassName: 'on-piskera-cheader',
        headerSectionsClassName: 'on-piskera-cheader-section',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: 'on-piskera-cheader-item',
        menuClassName: 'on-piskera-cheader-folder',
        menuLabelActiveClassName: 'on-cioovo-cheader-active',
        menuItemClassName: 'on-piskera-cheader-folder-item',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'piskera-cash-offer-desktop',
            content: 'CASH OFFER',
            url: 'https://cash-offer-piskera.netlify.app/',
          },
          {
            id: 'piskera-trade-in-desktop',
            content: 'TRADE IN',
            url: 'https://trade-in-piskera.netlify.app/',
          },
          {
            id: 'on-piskera-header-desktop-item-contact',
            content: 'CONTACT',
            url: 'https://www.kutterrealestate.com/contact/',
          },
          {
            id: 'on-piskera-header-desktop-item-listings-search',
            content: 'LISTINGS SEARCH',
            url: 'https://www.kutterrealestate.com/listings-search/',
          },
          {
            id: 'on-piskera-header-desktop-item-about',
            content: 'ABOUT',
            url: 'https://www.kutterrealestate.com/about/',
          },
          {
            id: 'on-piskera-header-desktop-item-blog',
            content: 'BLOG',
            url: 'https://www.kutterrealestate.com/blog/',
          },
          {
            id: 'on-piskera-header-desktop-item-home',
            content: 'HOME',
            url: 'https://www.kutterrealestate.com/',
          },
          {
            id: 'on-piskera-header-desktop-item-harvey-relief',
            content: 'HARVEY RELIEF',
            url: 'https://www.kutterrealestate.com/harvey-relief',
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: false,
          containerClassName: '',
          imageClassName: '',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: '',
          hide: false,
          reverse: false,
        },
        headerClassName: '',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: '',
        itemClassName: '',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [],
      },
    },
  ],
  footers: [
    {
      id: 'on-piskera-first-footer',
      display: true,
      className: 'on-piskera-first-footer-section',
      sectionClassName: 'on-piskera-first-footer-section-container',
      columns: [
        {
          id: 'on-piskera-first-footer-item-1',
          className: 'on-piskera-first-footer-item-1',
          type: 'menu',
          items: [
            {
              id: 'piskera-cash-offer-footer',
              type: 'heading',
              data: 'CASH OFFER',
              url: 'https://cash-offer-piskera.netlify.app/',
            },
            {
              id: 'piskera-trade-in-footer',
              type: 'heading',
              data: 'TRADE IN',
              url: 'https://trade-in-piskera.netlify.app/',
            },
            {
              id: 'on-piskera-first-footer-item-1-contact',
              data: 'CONTACT',
              type: 'heading',
              url: 'https://www.kutterrealestate.com/contact/',
            },
            {
              id: 'on-piskera-first-footer-item-1-listings-search',
              data: 'LISTINGS SEARCH',
              type: 'heading',
              url: 'https://www.kutterrealestate.com/listings-search/',
            },
            {
              id: 'on-piskera-first-footer-item-1-about',
              data: 'ABOUT',
              type: 'heading',
              url: 'https://www.kutterrealestate.com/about/',
            },
            {
              id: 'on-piskera-first-footer-item-1-blog',
              data: 'BLOG',
              type: 'heading',
              url: 'https://www.kutterrealestate.com/blog/',
            },
            {
              id: 'on-piskera-first-footer-item-1-home',
              data: 'HOME',
              type: 'heading',
              url: 'https://www.kutterrealestate.com/',
            },
            {
              id: 'on-piskera-first-footer-item-1-harvey-relief',
              data: 'HARVEY RELIEF',
              type: 'heading',
              url: 'https://www.kutterrealestate.com/harvey-relief',
            },
          ],
        },
      ],
    },
    {
      id: 'on-piskera-footer-second',
      display: true,
      className: 'on-piskera-footer-section on-piskera-footer-section-second',
      sectionClassName: 'on-piskera-footer-section-container-second',
      columns: [
        {
          id: 'on-piskera-footer-second-1',
          className: 'on-piskera-footer-column',
          style: { textAlign: 'left' },
          items: [
            {
              id: 'on-piskera-footer-second-1-1',
              type: 'heading',
              className: 'on-piskera-footer-heading',
              data: 'Kutter Real Estate Team',
            },
            {
              id: 'on-piskera-footer-second-1-2',
              type: 'heading',
              className: 'on-piskera-footer-heading',
              data: '832-588-1006',
            },
            {
              id: 'on-piskera-footer-second-1-3',
              type: 'heading',
              className: 'on-piskera-footer-heading',
              data: 'Email Me',
              url: 'mailto:Ryan@KutterRealEstate.com',
            },
            {
              id: 'piskera-footer-second-1-4',
              type: 'socialIcons',
              itemClassName: 'on-piskera-footer-socials pt-1',
              items: [
                {
                  id: 'piskera-face-footer',
                  url: 'https://www.facebook.com/kutterrealestate/',
                  iconTypeClassName: 'fab',
                  iconClassName: 'facebook',
                },
                {
                  id: 'piskera-insta-footer',
                  url: 'https://www.instagram.com/kutterrealestate/',
                  iconTypeClassName: 'fab',
                  iconClassName: 'instagram',
                },
              ],
            },
          ],
        },
        {
          id: 'piskera-footer-second-col-1-1-11',
          className: 'on-piskera-footer-col-two',
          style: {},
          items: [
            {
              id: 'piskera-footer-second-col-1-1-11-1',
              type: 'image',
              className: 'on-piskera-footer-img',
              alt: 'img',
              url: 'https://si-homelight.s3.amazonaws.com/sites/piskera/logo-footer-realtor.png',
            },
            {
              id: 'piskera-footer-second-col-1-1-11-original-1',
              type: 'heading',
              className: 'on-piskera-footer-heading-dre  pt',
              data: 'Only real estate professionals who are members of the NATIONAL ASSOCIATION OF REALTORS',
            },
            {
              id: 'piskera-footer-second-col-1-1-11-original-2',
              type: 'heading',
              className: 'on-piskera-footer-heading-dre  pt',
              data: ' may call themselves REALTORS',
            },
            {
              id: 'piskera-footer-second-col-1-1-11-original-3',
              type: 'heading',
              className: 'on-piskera-footer-heading-dre  pt',
              data: ' All REALTORS',
            },
            {
              id: 'piskera-footer-second-col-1-1-11-original-4',
              type: 'heading',
              className: 'on-piskera-footer-heading-dre  pt',
              data: 'must subscribe to NAR’s strict Code of Ethics, which is based on honesty, professionalism and the protection of the public.',
            },
            {
              id: 'piskera-footer-second-col-1-1-11-3',
              type: 'heading',
              className: 'on-piskera-footer-heading-address',
              style: {},
              data: 'Connect Realty 832-610-2830',
            },
            {
              id: 'piskera-footer-second-col-1-1-11-4',
              type: 'heading',
              className: 'on-piskera-footer-heading-the  pt-1',
              style: {},
              data: '8665 New Trails Dr. #125, The Woodlands, TX 77381',
            },
          ],
        },
      ],
    },
    {
      id: 'on-piskera-footer-inner',
      display: true,
      className: 'on-piskera-footer-inner',
      sectionClassName: 'on-piskera-footer-section-container-inner',
      columns: [
        {
          id: 'on-piskera-footer-inner-1',
          className: 'on-piskera-inner-links',
          style: {},
          items: [
            {
              id: 'on-piskera-footer-inner-1-1',
              type: 'menu',
              style: {},
              items: [
                {
                  id: 'on-piskera-footer-inner-1-1-1',
                  data: 'TREC INFORMATION ABOUT BROKERAGE SERVICES',
                  className: '',
                  url: 'https://www.trec.texas.gov/sites/default/files/pdf-forms/IABS%201-0.pdf',
                },
                {
                  id: 'on-piskera-footer-inner-1-1-2',
                  data: 'TREC CONSUMER PROTECTION NOTICE',
                  url: 'https://www.trec.texas.gov/sites/default/files/pdf-forms/CN%201-2.pdf',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'on-piskera-footer-third',
      display: true,
      className: 'on-piskera-footer-section on-piskera-footer-section-last',
      sectionClassName: 'on-piskera-footer-section-container-third',
      columns: [
        {
          id: 'on-piskera-footer-third-1',
          className: 'on-piskera-footer-checkmark',
          style: {},
          items: [
            {
              id: 'fon-piskera-footer-third-1-1',
              type: 'menu',
              className: 'pt-1 mb-0',
              style: {},
              items: [
                {
                  id: 'fon-piskera-footer-third-1-1-1',
                  data: '© 2022 Kutter Real Estate Team',
                  className: '',
                },
                {
                  id: 'fon-piskera-footer-third-1-1-2',
                  data: 'Created with  Placester',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  substitute: {},
  replace: {},
  siteData: {
    agency: 'The Kutter Group',
    agentDescription: 'Top agents in Conroe and North Houston Area, Texas',
    agentAvatar: 'https://si-homelight.s3.amazonaws.com/sites/piskera/agent.jpg',
    heroBackground: 'https://si-homelight.s3.amazonaws.com/sites/piskera/hero-bg.jpg',
  },
};
